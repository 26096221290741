.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.tweet-card{
    animation: MoveUpDown 5s linear infinite;
    /* position: absolute; */
    /* left: 0; */
    /* bottom: 0; */
}
  @keyframes MoveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(20px);
    }
  }


.footer-text{
  object-fit: fill;
  justify-content: space-between;
  font-size: 18vw;
  display: block;
  position: static;
  overflow: hidden;
  width: auto;
  max-width: none;
  color: var(--colors--horchata-400);
  letter-spacing: .8px;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: flex-start;
  font-family: Latina Essential,sans-serif;
  font-size: 16vw;
  font-weight: 900;
  line-height: 1;
  display: block;
}
.linkprops{
  /* margin-top: 2em;
  margin-right: 2em; */
  box-shadow: 5px 5px 0px 2px gray;
  padding: 0.5em;
}
.linkprops:hover{
  animation: ease 3s linear;
  box-shadow: 0px 0px 0px 0px white;
}
.nav-partitioner{
    height: 2px;
    width: 100%;
    background-color: black;
    flex: none;
    /* transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); */
    transform-style: preserve-3d;
}


.nav-text{
  color: black;
  letter-spacing: .05em;
  text-transform: uppercase;
  font-family: Clashgrotesk, sans-serif;
  font-size: 16vh;
  font-weight: 400;
  line-height: 1em;
}
.bg-mesh1{
  background-color:hsla(0,0%,0%,1);
  background-image:
  radial-gradient(at 40% 20%, hsla(25,0%,0%,1) 0px, transparent 50%),
  radial-gradient(at 80% 0%, hsla(0,9%,21%,1) 0px, transparent 50%),
  radial-gradient(at 0% 50%, hsla(136,31%,7%,1) 0px, transparent 50%),
  radial-gradient(at 80% 50%, hsla(184,33%,33%,1) 0px, transparent 50%),
  radial-gradient(at 0% 100%, hsla(355,100%,38%,0.29) 0px, transparent 50%),
  radial-gradient(at 80% 100%, hsla(0,0%,0%,1) 0px, transparent 50%),
  radial-gradient(at 29% 24%, hsla(78,22%,51%,1) 0px, transparent 50%);
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
